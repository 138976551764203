// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-partner-template-js": () => import("/Users/bradykeeling/Code/api-documentation/src/templates/partner-template.js" /* webpackChunkName: "component---src-templates-partner-template-js" */),
  "component---src-templates-customer-template-js": () => import("/Users/bradykeeling/Code/api-documentation/src/templates/customer-template.js" /* webpackChunkName: "component---src-templates-customer-template-js" */),
  "component---src-pages-404-js": () => import("/Users/bradykeeling/Code/api-documentation/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customer-sitemap-js": () => import("/Users/bradykeeling/Code/api-documentation/src/pages/customer/sitemap.js" /* webpackChunkName: "component---src-pages-customer-sitemap-js" */),
  "component---src-pages-index-js": () => import("/Users/bradykeeling/Code/api-documentation/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-sitemap-js": () => import("/Users/bradykeeling/Code/api-documentation/src/pages/partner/sitemap.js" /* webpackChunkName: "component---src-pages-partner-sitemap-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/bradykeeling/Code/api-documentation/.cache/data.json")

