module.exports = [{
      plugin: require('/Users/bradykeeling/Code/api-documentation/node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"buttonText":"COPY","svgIcon":"","toasterText":"Copied to Clipboard","toasterDuration":5000},
    },{
      plugin: require('/Users/bradykeeling/Code/api-documentation/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/bradykeeling/Code/api-documentation/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
