import './src/assets/styles/custom-code-buttons.scss';

require("prismjs/themes/prism.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

export function onRouteUpdate() {
  const directoryPath = document.location.pathname;
  const mainDirectory = directoryPath.split("/")  
  
  if (mainDirectory[1] === "docs" || 
      mainDirectory[1] === "v1.0" ||
      mainDirectory[1] === "reference"
      ){
    document.location.replace("https://developer.kindful.com");
  }
}

